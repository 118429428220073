import React from 'react'

import SimpleHead from 'components/head/SimpleHead'
import pageData from 'data/pages/Contents/download.json'
import DownloadLayout from 'templates/download'

export default function DownloadPage({ location }) {
  return (
    <DownloadLayout
      location={location}
      pageData={pageData}
      pathname="en"
      enableSubscribeNotice={false}
      noindex
    />
  )
}

export function Head() {
  const { title, description, keywords } = pageData

  return (
    <SimpleHead path="meta" title={title} description={description} keywords={keywords} noindex />
  )
}
